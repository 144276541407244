import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unser-team',
  templateUrl: './unser-team.component.html',
  styleUrls: ['./unser-team.component.css']
})
export class UnserTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
