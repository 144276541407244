import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {FacharztComponent} from './facharzt/facharzt.component';
import {UnserTeamComponent} from './unser-team/unser-team.component';
import {RaeumlichkeitenComponent} from './raeumlichkeiten/raeumlichkeiten.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PraeventionComponent } from './praevention/praevention.component';
import { QualifikationComponent } from './qualifikation/qualifikation.component';
import { VersorgungComponent } from './versorgung/versorgung.component';
import { DmpComponent } from './dmp/dmp.component';
import { SonstigesComponent } from './sonstiges/sonstiges.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AktuellesComponent } from './aktuelles/aktuelles.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        KontaktComponent,
        FacharztComponent,
        UnserTeamComponent,
        RaeumlichkeitenComponent,
        ImpressumComponent,
        PraeventionComponent,
        QualifikationComponent,
        VersorgungComponent,
        DmpComponent,
        SonstigesComponent,
        AktuellesComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        LeafletModule,
        LeafletModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
