import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {FacharztComponent} from './facharzt/facharzt.component';
import {RaeumlichkeitenComponent} from './raeumlichkeiten/raeumlichkeiten.component';
import {UnserTeamComponent} from './unser-team/unser-team.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {PraeventionComponent} from './praevention/praevention.component';
import {QualifikationComponent} from './qualifikation/qualifikation.component';
import {VersorgungComponent} from './versorgung/versorgung.component';
import {DmpComponent} from './dmp/dmp.component';
import {SonstigesComponent} from './sonstiges/sonstiges.component';
import {AktuellesComponent} from './aktuelles/aktuelles.component';


const routes: Routes = [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'kontakt', component: KontaktComponent, pathMatch: 'full'},
    {path: 'facharzt', component: FacharztComponent, pathMatch: 'full'},
    {path: 'unserteam', component: UnserTeamComponent, pathMatch: 'full'},
    {path: 'raeumlichkeiten', component: RaeumlichkeitenComponent, pathMatch: 'full'},
    {path: 'impressum', component: ImpressumComponent, pathMatch: 'full'},
    {path: 'praevention', component: PraeventionComponent, pathMatch: 'full'},
    {path: 'unserequalifikation', component: QualifikationComponent, pathMatch: 'full'},
    {path: 'hausaerztlicheversorgung', component: VersorgungComponent, pathMatch: 'full'},
    {path: 'dmp', component: DmpComponent, pathMatch: 'full'},
    {path: 'sonstiges', component: SonstigesComponent, pathMatch: 'full'},
    {path: 'aktuelles', component: AktuellesComponent, pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
