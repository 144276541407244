import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

    public ngOnInit() {
        $('ul.nav li.dropdown').hover(function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(200);
        }, function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(200);
        });
    }
}
