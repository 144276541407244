import {Component} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet';

@Component({
    selector: 'app-kontakt',
    templateUrl: './kontakt.component.html',
    styleUrls: ['./kontakt.component.css']
})
export class KontaktComponent {
    private map: L.Map;

    constructor() {
    }

    options = {
        layers: [
            L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors',
                maxZoom: 18,
                detectRetina: true
            })
        ],
        zoom: 5,
        center: L.latLng(51.258590, 7.758516)
    };

    onMapReady(map: L.Map) {
        this.map = map;

        const icon = L.icon({
            iconUrl: '../../assets/icons/praxis-logo-transparent.png',
            iconSize: [40, 40], // size of the icon
            iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -20] // point from which the popup should open relative to the iconAnchor
        });

        const startView = L.latLng(51.258590, 7.758516);

        L.marker([51.258590, 7.758516], {icon})
            .addTo(this.map)
            .bindPopup('<b>Praxis Dr. med. Federico Herrera Alemán</b> <br> Bahnhofstraße 6 <br> 58791 Werdohl');

        this.map.setView(startView, 13);
    }

}
