import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-raeumlichkeiten',
  templateUrl: './raeumlichkeiten.component.html',
  styleUrls: ['./raeumlichkeiten.component.css']
})
export class RaeumlichkeitenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
